import React from "react"

import Stack from "@kiwicom/orbit-components/lib/Stack"

import { Reservations } from "components/charging/reservations"
import Container from "components/container"
import { ChargeLayout } from "components/layout-custom"

const Page = () => {
  return (
    <ChargeLayout title="Charge History">
      <Container>
        <Stack spacing="XLarge">
          <Reservations display={["historical"]} />
        </Stack>
      </Container>
    </ChargeLayout>
  )
}

export default Page
